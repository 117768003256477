import React from 'react'

import Help from 'components/Help'
import urls from 'constants/urls'
import Utility from 'templates/Utility'

export default function Forbidden() {
  return (
    <Utility
      subtext="Womply Bills requires an active Womply account"
      button="Sign up"
      buttonTo={urls.WOMPLY_SIGN_UP}
      className="Forbidden">
      <Help />
    </Utility>
  )
}
